<script>
export default {
  name: 'BasicCURDMixin',
  props: {
    id: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      list: [],
      detail: {},
      loading: false,
      total: 0,
      params: {
        page: 1,
        sortBy: '-id'
      },
      paddingRow: false, // 在列表为空时，是否需要padding假数据,
      delErrMsg: '刪除失敗',
      forceDelErrMsg: '刪除失敗'
    }
  },
  watch: {
    'params.page'() {
      this.getList()
    }
  },
  methods: {
    search() {
      this.params.page = 1
      this.getList()
    },
    getList() {
      this.loading = true
      this.listApi(this.params ?? {}).then(({ data: { list, total }}) => {
        if (this.paddingRow !== false && total === 0) {
          list.push(this.paddingRow)
          total = 1
        }
        if (this.initRow) {
          list.forEach(this.initRow)
        }
        this.list = list
        this.total = total
      }).catch(() => {

      }).finally(() => {
        this.loading = false
      })
    },

    /**
     * 删除
     * @param id
     * @param index
     * @param forceDelete
     * @param onSuccess
     * @param title
     * @param message
     */
    del({
      id,
      index,
      forceDelete = 0,
      onSuccess = null,
      title = null,
      message = null
    }) {
      message = message ?? this.$t('common.forceDeleteConfirm')
      title = title ?? this.mt('common.confirm', 'common.delete')
      this.$confirm(
        message,
        title,
        {
          showClose: false,
          type: 'warning',
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          lockScroll: false
        }
      ).then(() => {
        this.delApi(id, { force_delete: forceDelete }).then(() => {
          onSuccess ? onSuccess(id, index) : (() => {
            this.$message.success(this.mt('common.delete', 'common.success'))
            this.list.splice(index, 1)
          })()
        }).catch(() => {
          this.$message.error(forceDelete ? this.forceDelErrMsg : this.delErrMsg)
        })
      })
    },
    getDetail() {
      this.loading = true
      this.detailApi(this.id).then(({ data }) => {
        this.detail = data
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * 上架
     * @param id
     * @param index
     */
    online(id, index) {
      this.$confirm(
        '確定上架嗎？',
        '上架',
        {
          showClose: false,
          type: 'warning',
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          lockScroll: false
        }
      ).then(() => {
        this.updateApi({ id, deleted_at: null }).then(() => {
          this.$message.success(this.mt('common.online', 'common.success'))
          this.getList()
        })
      })
    }
  }
}
</script>
