<template>
  <el-dialog
    :title="title"
    :width="width"
    visible
    :show-close="false"
    :lock-scroll="false"
  >
    <FormContent
      ref="form"
      @success="successHandler"
      @submit="submit"
    />
    <template v-slot:footer>
      <span class="dialog-footer">
        <el-button v-if="cancelButtonShow === true" @click="cancelWithCallback">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="submit">{{ $t('common.confirm') }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'DialogForm',
  props: {
    title: {
      type: String,
      default: '提示'
    },
    width: {
      type: String,
      default: '50%'
    },
    cancelButtonShow: {
      type: Boolean,
      default: true
    },
    onSuccess: {
      type: Function,
      required: false
    },
    onCancel: {
      type: Function,
      required: false
    }
  },
  methods: {
    submit() {
      if (this.$refs?.form?.$children[0]?.validate) { // 可能没有validate方法
        this.$refs?.form?.$children[0]?.validate().then(() => {
          this.$refs.form.submit()
        })
      } else {
        this.$refs.form.submit()
      }
    },
    cancelWithCallback() {
      this.cancel()
      if (this.onCancel) {
        this.onCancel()
      }
    },
    cancel() {
      this.$destroy()
      this.$el.parentNode?.removeChild(this.$el)
      delete window.dialogForm
    },
    successHandler(row) {
      if (this.onSuccess) {
        this.onSuccess(row)
      }
      this.cancel()
    }
  }
}
</script>
