<script>
import request from '@/utils/request'
export default {
  name: 'DialogFormMixin',
  data() {
    return {
      loading: false,
      url: '',
      method: '',
      successMsg: '保存成功'
    }
  },
  methods: {
    dataSubmited() {
      return this.form
    },
    submit() {
      this.loading = true
      request({
        url: this.url,
        method: this.method,
        data: this.dataSubmited()
      }).then((res) => {
        this.success(res)
      }).finally(() => {
        this.loading = false
      })
    },
    success() {
      if (this.successMsg !== false) {
        this.$message.success(this.successMsg)
      }
      this.$emit('success', this.form)
    }
  }
}
</script>
