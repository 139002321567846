import request from '@/utils/request'

export function Curd(url) {
  this.url = url ?? 'url'
  this.listApi = function(params = {}) {
    return request({
      url: this.url,
      method: 'get',
      params// : Object.assign({ sortBy: '-id' }, params)
    })
  }.bind(this)

  this.totalApi = function(params = {}) {
    return request({
      url: this.url + '/total',
      method: 'get',
      params// : Object.assign({ sortBy: '-id' }, params)
    })
  }.bind(this)

  this.detailApi = function(id, params = {}) {
    return request({
      url: `${this.url}/${id}`,
      method: 'get',
      params// : Object.assign({ sortBy: '-id' }, params)
    })
  }.bind(this)

  this.storeApi = function(data) {
    return request({
      url: this.url,
      method: 'post',
      data
    })
  }.bind(this)

  this.updateApi = function(data) {
    return request({
      url: `${this.url}/${data.id}`,
      method: 'put',
      data: _.omit(data, ['id', 'created_at', 'created_by', 'updated_at'])
    })
  }.bind(this)

  this.delApi = function(ids, params = {}) {
    if (_.isArray(ids)) {
      ids = ids.join(',')
    }
    return request({
      url: `${this.url}/${ids}`,
      method: 'delete',
      params
    })
  }.bind(this)
}

