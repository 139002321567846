var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "navbar" } },
    [
      _c(
        "router-link",
        { attrs: { to: _vm.isDataPlatform ? "/device/mobile" : "/" } },
        [
          _c("div", { attrs: { id: "logo" } }, [
            _c(
              "div",
              { attrs: { id: "logo-img" } },
              [_c("svg-icon", { attrs: { "icon-class": "iylogo" } })],
              1
            ),
            _c("span", { attrs: { id: "logo-text" } }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        { attrs: { id: "setting" } },
        [
          _c(
            "RouterLink",
            {
              attrs: {
                to: _vm.isOperatePlatform ? "/device/mobile" : "/",
                title: _vm.isOperatePlatform ? "數據後台" : "營運後台",
              },
            },
            [
              _c("svg-icon", {
                staticClass: "setting-item",
                attrs: {
                  "icon-class": _vm.isOperatePlatform
                    ? "data-platform"
                    : "operate-platform",
                },
              }),
            ],
            1
          ),
          _c(
            "el-dropdown",
            { on: { command: _vm.handleCommand } },
            [
              _c("span", { attrs: { id: "setting-text" } }, [
                _vm._v(_vm._s(_vm.$store.getters.userName)),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "chgPwd" } }, [
                    _vm._v("修改密碼"),
                  ]),
                  _c("el-dropdown-item", { attrs: { command: "logout" } }, [
                    _vm._v(_vm._s(_vm.$t("views.login.singOut"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }