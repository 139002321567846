<template>
  <el-form :rules="rules" :model="form" label-width="110px">
    <el-row>
      <el-col>
        <el-form-item
          label="原密碼："
          prop="pwd_old"
        >
          <el-input
            v-model="form.pwd_old"
            show-password
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-form-item
          label="新密碼："
          prop="pwd_new"
        >
          <el-input
            v-model="form.pwd_new"
            show-password
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-form-item
          label="確認新密碼："
          prop="pwd_confirm"
        >
          <el-input
            v-model="form.pwd_confirm"
            show-password
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
export default {
  name: 'ChgPwd',
  data() {
    return {
      form: {

      }
    }
  },
  computed: {
    rules() {
      const noSame = (rule, value, callback) => {
        this.form.pwd_old === this.form.pwd_new
          ? callback(new Error('新密碼不能與原密碼一致'))
          : callback()
      }
      const confirm = (rule, value, callback) => {
        this.form.pwd_new === this.form.pwd_confirm
          ? callback()
          : callback(new Error('兩次輸入的新密碼不一致'))
      }
      return {
        pwd_old: { required: true, trigger: 'blur' },
        pwd_new: [
          { required: true, trigger: 'blur' },
          { pattern: /^.*(?=.{6})(?=.*\d).*$/, message: '密碼必須包含數字，長度至少6位' },
          { validator: noSame, trigger: 'blur' }
        ],
        pwd_confirm: [
          { required: true, trigger: 'blur' },
          { validator: confirm, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submit() {
      this.$store.dispatch('user/chgPwd', this.form)
        .then(() => {
          this.$message.success('修改密碼成功')
          this.success()
        })
        .catch((err) => {
          if (err.response.data.code === 401003) {
            this.$message.error('原密碼錯誤')
          }
        })
    }
  }
}
</script>
