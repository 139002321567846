<template>
  <div>
    <div id="sn">異常：{{ notification.notificable.sn }}</div>
    <span
      id="download"
      @click="downloadLarge(notification.notificable.log)"
    >按此下載log檔案</span>
  </div>
</template>

<script>
import DownloadMixin from '@/components/Mixins/DownloadMixin'
export default {
  name: 'CrashLog',
  mixins: [DownloadMixin],
  props: {
    notification: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/variables";
div#sn{
  font-weight: 700;
  font-size: 18px;
}
span#download{
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  /* or 183% */
  /* active */
  color: $purpel;
  line-height: 32px;
}
</style>
