var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "root" } },
    [
      _c(
        "ElRow",
        { attrs: { id: "container" } },
        [
          _c(
            "ElCol",
            { attrs: { span: 2 } },
            [
              _c("svg-icon", {
                attrs: { id: "icon", "icon-class": "notification-warning" },
              }),
            ],
            1
          ),
          _c(
            "ElCol",
            { attrs: { span: 20 } },
            [
              _c(
                "ElRow",
                { attrs: { id: "boddy" } },
                [
                  _c(_vm.notification.notificable_type.substr(4), {
                    tag: "component",
                    attrs: { notification: _vm.notification },
                  }),
                ],
                1
              ),
              _c(
                "ElRow",
                { attrs: { id: "footer" } },
                [
                  _c("ElCol", { attrs: { id: "time", span: 14 } }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.notification.notificable.created_at.substr(0, 16)
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "ElCol",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { id: "button", type: "primary", plain: "" },
                          on: { click: _vm.resolve },
                        },
                        [_vm._v("標示已解決")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }