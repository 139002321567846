import request from '@/utils/request'

export const STATUS_NORMAL = 1
export const STATUS_DISABLE = 2
export function login(data) {
  data = {
    ...data,
    grant_type: 'password',
    client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
    client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
    scope: ''
  }
  return request({
    url: process.env.VUE_APP_BASE_API.slice(0, -4) + '/oauth/token',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/user/current',
    method: 'get'
  })
}

export function isLogin() {
  return request({
    url: '/user/is-login',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: '/vue-element-admin/user/logout',
    method: 'post'
  })
}

/**
 * 用户主动修改密码
 */
export function chgPwd(data) {
  return request({
    url: '/user/chg-pwd',
    method: 'patch',
    data
  })
}

/**
 * 更新用户的密码
 */
export function updatePwd(id) {
  return request({
    url: `/user/${id}/update-pwd`,
    method: 'patch'
  })
}

import { Curd } from '@/api/basic-curd'
export const url = 'user'

const curd = new Curd(url)

export const storeApi = curd.storeApi
export const listApi = curd.listApi
export const delApi = curd.delApi
export const updateApi = curd.updateApi
export function disableByEmailApi(email) {
  return request({
    url: '/user/disable-by-email',
    method: 'patch',
    data: { email }
  })
}

export const ROLES = {
  tuibo: {
    label: '推播組',
    desc: '主打星、消息、推薦曲庫、數據歌單、教學、快捷鍵、App Banners、暖場、問候語等',
    permissions: ['tuibo']
  },
  // register: {
  //   label: '註冊資料組',
  //   permissions: ['register']
  // },
  data: {
    label: '數據觀看組',
    permissions: ['data']
  },
  firmware: {
    label: 'Firmware組',
    desc: 'firmware、當機紀錄等',
    permissions: ['firmware', 'crashlog']
  },
  user: {
    label: '資安管理組',
    permissions: ['user']
  },
  service: {
    label: '服務組',
    desc: '雲端資源、YT資源、歌曲管理、評分、語音命令等',
    permissions: ['downloadSongs', 'yt', 'songs', 'songsScore', 'speech']
  }
}
