<template>
  <span>請向「管理人員」請求開啟權限</span>
</template>
<script>
export default {
  name: 'AccessDeny',
  data() {
    return {
      successMsg: false
    }
  },
  methods: {
    submit() {
      this.success()
    }
  }
}
</script>
