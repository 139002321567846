import { Curd } from '@/api/basic-curd'
import request from '@/utils/request'
export const url = 'notification'
const curd = new Curd(url)

export function clearApi() {
  return request({
    url: url + '/all',
    method: 'delete'
  })
}

export const listApi = curd.listApi
export const totalApi = curd.totalApi
export const delApi = curd.delApi

