<script>
import { isLogin } from '@/api/user'

export default {
  name: 'CheckLoginMixin',
  data() {
    return {
      show: false
    }
  },
  async created() {
    /* 显示页面之前先检查登录 */
    await isLogin().then(() => {
      this.show = true
    })
  }
}
</script>
