import Cookies from 'js-cookie'
import store from '@/store'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token, attributes = {}) {
  return Cookies.set(TokenKey, token, attributes)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

const EmailKey = 'email'

export function getEmail() {
  return Cookies.get(EmailKey)
}

export function setEmail(email, attributes = {}) {
  return Cookies.set(EmailKey, email, attributes)
}

export function removeEmail() {
  return Cookies.remove(EmailKey)
}

const RolesKey = 'roles'

export function getRoles() {
  return Cookies.getJSON(RolesKey) ?? []
}

export function setRoles(roles, attributes = {}) {
  return Cookies.set(RolesKey, JSON.stringify(roles), attributes)
}

export function removeRoles() {
  return Cookies.remove(RolesKey)
}

export function getAuthorizatin() {
  return 'Bearer ' + (store.getters.token ?? '')
}
