import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import { can, empty } from '@/utils'
import DialogForm from '@/components/DialogForm/dialogForm'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async(to, from, next) => {
  /* 在有dialogForm时，自动删除 */
    window.dialogForm?.cancel()

    // start progress bar
    NProgress.start()

    // set page title
    document.title = getPageTitle(to.meta.title)

    // determine whether the user has logged in
    const hasToken = getToken()

    if (!hasToken) {
      next()
    }

    store.dispatch('user/calPermissions')

    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      if (!empty(to.meta.perm)) {
        can(to.meta.perm) ? next() : DialogForm({
          form: require('./views/error-page/access-deny'),
          dialogProps: {
            title: '無編輯權限',
            width: '300px',
            cancelButtonShow: false,
          },
          onSuccess() {
            // next({ name: 'home' })
          }
        })
      } else {
        next()
      }
      NProgress.done()
    }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
