<template>
  <div id="app-container">
    <IyNavbar
      type="data_platform"
    />
    <sidebar class="sidebar-container" />
    <div style="padding:80px 33px 0px 230px">
      <IyAppMain />
    </div>
  </div>
</template>

<script>
import IyNavbar from '@/layout/components/IyNavbar'
import { Sidebar } from './components'
import IyAppMain from '@/layout/components/IyAppMain'
import store from '@/store'
import router from '@/router'

export default {
  name: 'IyDataLayout',
  components: { IyAppMain, IyNavbar, Sidebar },
  async beforeMount() {
    /* 计算菜单 */
    const accessRoutes = await store.dispatch('permission/generateRoutes', [], { root: true })
    router.addRoutes(accessRoutes)
  }
}
</script>
<style lang="scss" scoped>
div#app-container .sidebar-container{
  top:48px;
}
</style>
