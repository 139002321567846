var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app-container" } },
    [
      _c("IyNavbar", { attrs: { type: "data_platform" } }),
      _c("sidebar", { staticClass: "sidebar-container" }),
      _c(
        "div",
        { staticStyle: { padding: "80px 33px 0px 230px" } },
        [_c("IyAppMain")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }