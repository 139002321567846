var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "sn" } }, [
      _vm._v("異常：" + _vm._s(_vm.notification.notificable.sn)),
    ]),
    _c(
      "span",
      {
        attrs: { id: "download" },
        on: {
          click: function ($event) {
            return _vm.downloadLarge(_vm.notification.notificable.log)
          },
        },
      },
      [_vm._v("按此下載log檔案")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }