import { render, staticRenderFns } from "./IyNavbar.vue?vue&type=template&id=f1a44ae6&scoped=true&"
import script from "./IyNavbar.vue?vue&type=script&lang=js&"
export * from "./IyNavbar.vue?vue&type=script&lang=js&"
import style0 from "./IyNavbar.vue?vue&type=style&index=0&id=f1a44ae6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1a44ae6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/iy_web_test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f1a44ae6')) {
      api.createRecord('f1a44ae6', component.options)
    } else {
      api.reload('f1a44ae6', component.options)
    }
    module.hot.accept("./IyNavbar.vue?vue&type=template&id=f1a44ae6&scoped=true&", function () {
      api.rerender('f1a44ae6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/IyNavbar.vue"
export default component.exports