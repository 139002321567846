<template>
  <div
    id="root"
  >
    <ElRow id="container">
      <ElCol :span="2">
        <svg-icon
          id="icon"
          icon-class="notification-warning"
        ></svg-icon>
      </ElCol>

      <ElCol :span="20">
        <ElRow id="boddy">
          <component
            :is="notification.notificable_type.substr(4)"
            :notification="notification"
          />
        </ElRow>
        <ElRow id="footer">
          <ElCol id="time" :span="14">
            {{ notification.notificable.created_at.substr(0,16) }}
          </ElCol>
          <ElCol :span="8">
            <el-button
              id="button"
              type="primary"
              plain
              @click="resolve"
            >標示已解決</el-button>
          </ElCol>
        </ElRow>
      </ElCol>
    </ElRow>
  </div>
</template>

<script>
import BasicCURDMixin from '@/components/Mixins/BasicCURDMixin'
import CrashLog from '@/components/Notification/CrashLog'
import { delApi } from '@/api/notification'
export default {
  name: 'NotificationItem',
  components: {
    CrashLog
  },
  mixins: [BasicCURDMixin],
  props: {
    notification: {
      type: Object,
      required: true
    },
    /**
     * 用于删除
     */
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    resolve() {
      this.$confirm(
        '此項目紀錄會被永久刪除',
        '確認解決並刪除',
        {
          showClose: false,
          type: 'warning',
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          lockScroll: false
        }
      ).then(() => {
        delApi(this.notification.id).then(() => {
          this.$emit('destroyed', { index: this.index })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/styles/variables.scss';
div#root{
  :hover{
    background-color: #DED0EF;
  }
  div#container{
    padding: 30px 10px 0 20px;

    border-bottom: 1px solid #F5F5F5;

    #icon{
      width: 19px;
      height: 19px;
      fill:$purpel;
    }

    div#footer{
      padding: 20px 0 15px 0;
      div#time{
        font-weight: 400;
        font-size: 14px;
        line-height: 36px;
      }
      #button:hover{
        color:$purpel;
      }
    }
  }
}
</style>
