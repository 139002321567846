import { getInfo, login, chgPwd } from '@/api/user'
import { getToken, removeToken, setToken, getEmail, setEmail, removeEmail, setRoles, removeRoles, getRoles } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import { ROLES } from '@/api/user'
const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: getRoles(),
  permissions: [],
  email: getEmail()
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_PERMISSIONS: (state) => {
    state.permissions = []
    state.roles.forEach((role) => {
      state.permissions = state.permissions.concat(ROLES[role]?.permissions ?? [])
    })
  }
}

const actions = {
  // user login
  login({ commit, dispatch }, userInfo) {
    const { email, pwd, remember_me } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: email.trim(), password: pwd }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.access_token)
        commit('SET_EMAIL', email)
        const expires = remember_me ? 1 : null
        setToken(data.access_token, { expires })
        setEmail(email, { expires })

        dispatch('getInfo', { expires }).then((res) => {
          resolve()
        }).catch(error => {
          reject(error)
        })
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }, { expires = 1 } = {}) {
    return new Promise((resolve, reject) => {
      getInfo().then(response => {
        let { data: { roles, name }} = response
        roles = roles ?? []

        setRoles(roles, { expires })
        commit('SET_ROLES', roles)
        commit('SET_PERMISSIONS')
        commit('SET_NAME', name)
        resolve({ roles })
      }).catch(error => {
        reject(error)
      })
    })
  },

  calPermissions({ commit }) {
    commit('SET_PERMISSIONS')
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_PERMISSIONS', [])
      removeToken()
      removeEmail()
      removeRoles()
      resetRouter()
      router.push({ name: 'login' })

      resolve()
    })
  },

  chgPwd({ commit }, payload) {
    return new Promise((resolve, reject) => {
      chgPwd(payload).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
