<template>
  <div id="navbar">
    <router-link
      :to="isDataPlatform ? '/device/mobile' : '/' "
    >
      <div id="logo">
        <div id="logo-img">
          <svg-icon icon-class="iylogo" />
        </div>
        <span id="logo-text">{{ title }}</span>
      </div>
    </router-link>

    <div id="setting">
      <RouterLink
        :to="isOperatePlatform ? '/device/mobile' : '/' "
        :title="isOperatePlatform ? '數據後台' : '營運後台' "
      >
        <svg-icon :icon-class="isOperatePlatform ? 'data-platform' : 'operate-platform'" class="setting-item" />
      </RouterLink>
      <!-- <Notification
        v-if="isOperatePlatform"
      /> -->
      <el-dropdown @command="handleCommand">
        <span id="setting-text">{{ $store.getters.userName }}</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="chgPwd">修改密碼</el-dropdown-item>
          <el-dropdown-item command="logout">{{ $t('views.login.singOut') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import '@/components/BackToTop'
import Notification from '@/components/Notification'
export default {
  name: 'IyNavbar',
  components: {
    Notification
  },
  props: {
    type: {
      type: String,
      default: 'operate_platform'// operate_platform运营后台，data_platform 数据后台
    }
  },
  computed: {
    title() {
      return this.isOperatePlatform ? '營運後台' : '數據後台'
    },
    isOperatePlatform() {
      return this.type === 'operate_platform'
    },
    isDataPlatform() {
      return this.type === 'data_platform'
    }
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 'logout':
          this.$store.dispatch('user/logout')
          break
        case 'chgPwd':
          this.chgPwd()
          break
        default:
      }
    },
    chgPwd() {
      this.$dialogForm({
        form: require('@/views/home/User/forms/ChgPwd'),
        dialogProps: {
          title: '修改密碼',
          width: '350px'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
div#navbar {
  /* global-header */
  position: fixed;
  top:0px;
  left: 0px;
  width: 100%;
  z-index: 99;

  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;

  height: $iyNavbarHeight;

  background: #001529;
  color: white;

  div#logo {
    height: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
  }

  span#logo-text {

    font-family: $roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 22px;
    /* identical to box height, or 92% */
    text-align: center;
    padding:0 0 7px 10px;

  }

  span#setting-text {
    /* Body 14 / regular */
    font-family: $roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: white;
    cursor: pointer;
  }

  div#logo-img svg {
    width: 146px;
    height: 40px;
    fill: $lightPurple;
    margin-right: 15px;
  }
}
</style>
