var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        width: _vm.width,
        visible: "",
        "show-close": false,
        "lock-scroll": false,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "span",
                { staticClass: "dialog-footer" },
                [
                  _vm.cancelButtonShow === true
                    ? _c(
                        "el-button",
                        { on: { click: _vm.cancelWithCallback } },
                        [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                      )
                    : _vm._e(),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.submit } },
                    [_vm._v(_vm._s(_vm.$t("common.confirm")))]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("FormContent", {
        ref: "form",
        on: { success: _vm.successHandler, submit: _vm.submit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }