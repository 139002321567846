// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "rgba(0, 0, 0, 0.85)",
	"menuActiveText": "#7600DF",
	"subMenuActiveText": "#7600DF",
	"menuBg": "white",
	"menuHover": "#DED0EF",
	"subMenuBg": "white",
	"subMenuHover": "#DED0EF",
	"sideBarWidth": "210px",
	"squareImgWidth": "140px",
	"halfSquareImgWidth": "67.9px",
	"rectangleImgWidth": "280px",
	"qrCodeWidth": "80px",
	"lightPurple": "#A66ADB",
	"purpel": "#7600DF",
	"statusStandByColor": "#F2C94C",
	"statusOnlineColor": "#219653",
	"statusStashColor": "#7600DF",
	"statusOfflineColor": "gray",
	"statusTestColor": "#FF0000",
	"iyNavbarHeight": "48px"
};
module.exports = exports;
