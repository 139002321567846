var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-badge",
    {
      staticStyle: { "margin-right": "20px" },
      attrs: { value: _vm.total, hidden: _vm.total < 1 },
    },
    [
      _c("i", {
        staticClass: "setting-item el-icon-message-solid",
        attrs: { title: "系統通知" },
        on: { click: _vm.showNotification },
      }),
      _c(
        "el-drawer",
        {
          staticStyle: { color: "rgba(0, 0, 0, 0.85)" },
          attrs: {
            title: "系統通知",
            visible: _vm.show,
            modal: false,
            size: "326px",
            "custom-class": "notification-drawer",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c(
                    "span",
                    {
                      attrs: {
                        role: "heading",
                        tabindex: "0",
                        title: "系統通知",
                      },
                    },
                    [_vm._v("系統通知")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.total < 1,
                        type: "primary",
                        plain: "",
                      },
                      on: { click: _vm.clear },
                    },
                    [_vm._v("全部清空")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { id: "notification-container" },
            },
            _vm._l(_vm.list, function (item, index) {
              return _c("NotificationItem", {
                key: item.id,
                attrs: { notification: item, index: index },
                on: { destroyed: _vm.handleDestroyed },
              })
            }),
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }