<template>
  <el-badge
    :value="total"
    style="margin-right: 20px"
    :hidden="total < 1"
  >
    <i
      class="setting-item el-icon-message-solid"
      title="系統通知"
      @click="showNotification"
    />
    <el-drawer
      title="系統通知"
      :visible.sync="show"
      :modal="false"
      size="326px"
      style="color:rgba(0, 0, 0, 0.85);"
      custom-class="notification-drawer"
    >
      <template v-slot:title>
        <span role="heading" tabindex="0" title="系統通知">系統通知</span>
        <el-button
          :disabled="total<1"
          type="primary"
          plain
          @click="clear"
        >全部清空</el-button>
      </template>
      <div
        id="notification-container"
        v-loading="loading"
      >
        <NotificationItem
          v-for="(item,index) in list"
          :key="item.id"
          :notification="item"
          :index="index"
          @destroyed="handleDestroyed"
        ></NotificationItem>
      </div>
    </el-drawer>
  </el-badge>

</template>

<script>
import { listApi, totalApi, clearApi } from '@/api/notification'
import NotificationItem from '@/components/Notification/NotificationItem'
export default {
  name: 'Notification',
  components: { NotificationItem },
  data() {
    return {
      show: false,
      loading: false,
      list: [],
      total: 0
    }
  },
  created() {
    this.getTotal()
  },
  methods: {
    getTotal() {
      totalApi().then(({ data: { total }}) => {
        this.total = total > 999 ? '999+' : total
      })
    },
    showNotification() {
      this.show = true
      this.loading = true
      listApi({ size: 0 }).then(({ data: { list, total }}) => {
        this.list = list
        this.total = total > 999 ? '999+' : total
      }).finally(() => {
        this.loading = false
      })
    },
    handleDestroyed({ index }) {
      this.list.splice(index, 1)
      this.total--
    },
    clear() {
      this.$confirm(
        '確認清空全部的通知嗎？',
        '確認清空',
        {
          showClose: false,
          type: 'warning',
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true,
          lockScroll: false
        }
      ).then(() => {
        this.loading = true
        clearApi().then(() => {
          this.list = []
          this.total = 0
        }).finally(() => {
          this.loading = false
        })
      })
    }
  }
}
</script>
<style lang="scss">
div.notification-drawer{
  section.el-drawer__body{
    height: 100%;
    div#notification-container{
      height: 90%;
      overflow-y: scroll;
    }
  }
}
</style>
