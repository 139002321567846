<template>
  <div
    v-if="show"
    id="app-container"
  >
    <IyNavbar />
    <IyAppMain style="margin-top:48px;" />
  </div>
</template>

<script>
import IyNavbar from '@/layout/components/IyNavbar'
import IyAppMain from '@/layout/components/IyAppMain'
import CheckLoginMixin from '@/layout/CheckLoginMixin'
export default {
  name: 'IyLayout',
  components: { IyAppMain, IyNavbar },
  mixins: [CheckLoginMixin]
}
</script>
