import dialogForm from './DialogForm.vue'
import Vue from 'vue'
import i18n from '@/lang'
import DialogFormMixin from './Mixin'
import md5 from 'md5'
import { can, empty } from '@/utils'
import AccessDeny from '@/views/error-page/access-deny'

const DialogFormConstructor = Vue.extend(dialogForm)

const DialogForm = function({
  form, // 表单vue文件
  onSuccess, // 成功的回调,
  onCancel, // 取消的回调
  row = {}, // 编辑的数据
  meta = {}, // 其他数据
  dialogProps = {}, // el-dialog组件的props
  perm = '' // 需要的权限
}) {
  let realVue
  if (!empty(perm) && !can(perm)) { // 没有权限
    realVue = _.cloneDeep(AccessDeny)
    dialogProps = {
      title: '無編輯權限',
      width: '300px',
      cancelButtonShow: false,
    }
  } else {
    realVue = _.cloneDeep(form.default)
  }
  if (realVue.mixins instanceof Array) {
    realVue.mixins.unshift(DialogFormMixin)
  } else {
    realVue.mixins = [DialogFormMixin]
  }
  const originData = realVue.data ? realVue.data() : {}
  const isEdit = 'id' in row
  if (isEdit) {
    originData.url += `/${row.id}`
  }
  realVue.data = function() {
    return {
      ...originData,
      form: row,
      isEdit,
      method: empty(originData.method) ? (isEdit ? 'put' : 'post') : originData.method,
      originMd5: md5(JSON.stringify(row)),
      meta
    }
  }
  dialogProps.onSuccess = onSuccess || this?.getList
  dialogProps.onCancel = onCancel || []
  Vue.component('FormContent', realVue)

  const instance = new DialogFormConstructor({
    data: realVue.data,
    parent: this,
    propsData: dialogProps,
    i18n
  }).$mount()
  document.body.appendChild(instance.$el)
  window.dialogForm = instance

  // 不能用下面这种挂载方式，原因未知
  // new Constructor().$mount('body')
}
export default DialogForm
