import axios from 'axios'
import store from '@/store'
import { getAuthorizatin } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 10000 // request timeout,
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    config.headers['Authorization'] = getAuthorizatin()

    return config
  },
  error => {
    // do something with request error
    // console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // store.dispatch('user/resetToken').then(() => {
    //   location.reload()
    // })
    return response
  },
  (error) => {
    // console.log(error) // for debug
    if (error.response.data.code === 401001) {
      store.dispatch('user/logout')
      return Promise.reject(error)
    }
    // Message({
    //   message: error.message || 'Service Unavailable',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  }
)

export default service
