import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import Element from 'element-ui'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import i18n from './lang' // internationalization
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters

import DialogForm from '@/components/DialogForm/dialogForm.js'
Vue.prototype.$dialogForm = DialogForm
import _ from 'lodash'
import { can } from '@/utils'
import { publicOssFilter, youtubeVideoFilter } from './filters'
Vue.prototype._ = _

// 全局mixin
Vue.mixin({
  methods: {
    /**
     * 批量翻译
     * @param args
     * @returns String
     */
    mt(...args) {
      return args.reduce((translated, item) => {
        return translated + this.$t(item)
      }, '')
    },
    lt(...agrs) {
      return this.mt(...agrs) + '：'
    },
    can
  }
})
// 全局filter
Vue.filter('publicOss', publicOssFilter)
Vue.filter('youtubeVideo', youtubeVideoFilter)

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
